import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "projectId", "hoverLabel", "objectFit", "showPublished", "Menu", "hasGradientOverlay", "isMenuOpen", "fallbackData", "showStaticImage", "videoUrl"],
  _excluded2 = ["children"];
var __jsx = React.createElement;
import { Box, ThumbnailCard, ThumbnailCardLabel, ThumbnailCardSubtitle, ThumbnailImage, ThumbnailVideo } from '@playful/design_system';
import { hasPrivateContent } from '@playful/runtime';
import React from 'react';
import { useProject } from '../../hooks/useProject';
import { useProjectPreview } from '../ProjectPreview';
export function useProjectThumb(_ref) {
  var projectId = _ref.projectId,
    videoUrl = _ref.videoUrl,
    showStaticImage = _ref.showStaticImage,
    fallbackData = _ref.projectInfo,
    showPublished = _ref.showPublished;
  var _useProject = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject.projectInfo;
  var _useProjectPreview = useProjectPreview({
      projectInfo: projectInfo,
      showPublished: showPublished
    }),
    _useProjectPreview$pr = _useProjectPreview.previewUrl,
    previewUrl = _useProjectPreview$pr === void 0 ? '' : _useProjectPreview$pr;
  var shouldUseVideo = videoUrl && !showStaticImage;
  return {
    type: shouldUseVideo ? 'video' : 'image',
    mediaUrl: shouldUseVideo ? videoUrl : previewUrl,
    poster: shouldUseVideo ? previewUrl : undefined
  };
}
export var ProjectThumbnailCard = function ProjectThumbnailCard(_ref2) {
  var children = _ref2.children,
    projectId = _ref2.projectId,
    hoverLabel = _ref2.hoverLabel,
    objectFit = _ref2.objectFit,
    _ref2$showPublished = _ref2.showPublished,
    showPublished = _ref2$showPublished === void 0 ? true : _ref2$showPublished,
    Menu = _ref2.Menu,
    _ref2$hasGradientOver = _ref2.hasGradientOverlay,
    hasGradientOverlay = _ref2$hasGradientOver === void 0 ? false : _ref2$hasGradientOver,
    isMenuOpen = _ref2.isMenuOpen,
    fallbackData = _ref2.fallbackData,
    showStaticImage = _ref2.showStaticImage,
    videoUrl = _ref2.videoUrl,
    thumbProps = _objectWithoutProperties(_ref2, _excluded);
  var _useProject2 = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject2.projectInfo;
  var _useProjectThumb = useProjectThumb({
      projectId: projectId,
      videoUrl: videoUrl,
      showStaticImage: showStaticImage,
      projectInfo: fallbackData,
      showPublished: showPublished
    }),
    type = _useProjectThumb.type,
    mediaUrl = _useProjectThumb.mediaUrl,
    poster = _useProjectThumb.poster;
  var thumbnailToUse;

  // Override Thumbnail with video if defined in mp4 previews
  if (type === 'video') {
    thumbnailToUse = __jsx(ThumbnailVideo, {
      src: mediaUrl,
      poster: poster,
      title: projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.title,
      height: '100%'
    });
  } else if (mediaUrl) {
    thumbnailToUse = __jsx(ThumbnailImage, {
      src: mediaUrl,
      alt: projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.title,
      height: '100%'
    });
  }
  if (!projectInfo) return null;
  return __jsx(ThumbnailCard, _extends({
    Thumbnail: thumbnailToUse,
    flexGrow: 1,
    objectFit: objectFit,
    Subtitle: hoverLabel && __jsx(ThumbnailCardSubtitle, null, hoverLabel),
    Menu: Menu,
    hasGradientOverlay: hasGradientOverlay,
    isMenuOpen: isMenuOpen,
    "data-cy": "project-card",
    "data-cy-published": !!projectInfo.published,
    "data-cy-owner": projectInfo.owner,
    "data-cy-title": projectInfo.title,
    "data-cy-id": projectInfo.id,
    "data-private": hasPrivateContent(projectInfo) ? true : undefined,
    version: projectInfo.version
  }, thumbProps), children);
};
export function ProjectThumbnailLabel(_ref3) {
  var children = _ref3.children,
    props = _objectWithoutProperties(_ref3, _excluded2);
  return __jsx(Box, _extends({
    display: "flex",
    justifyContent: "space-between",
    mt: 2,
    gap: 1,
    alignItems: "flex-start",
    wordBreak: "break-all"
  }, props), __jsx(ThumbnailCardLabel, null, children));
}