var __jsx = React.createElement;
import { RouteLink } from '@playful/design_system';
import React from 'react';
import { useProject } from '../../hooks/useProject';
import { buildUserRoute } from '../../paths';
import { PreviewThumbLink } from './PreviewCard';
import { ProjectThumbnailLabel } from './ProjectThumbnailCard';
export function CommunityCard(props) {
  var projectId = props.projectId,
    fallbackData = props.fallbackData,
    hideLabel = props.hideLabel;
  var _useProject = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject.projectInfo;
  if (!projectInfo) return null;
  return __jsx("div", null, __jsx(PreviewThumbLink, props), !hideLabel && __jsx(RouteLink, {
    href: buildUserRoute(projectInfo.ownerName)
  }, __jsx(ProjectThumbnailLabel, null, "@".concat(projectInfo.ownerName))));
}