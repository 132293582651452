import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["ThumbLinkProps"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React from 'react';
import { useProject } from '../../hooks/useProject';
import { buildProjectEditRoute } from '../../paths';
import { ProjectThumbnailCard, ProjectThumbnailLabel } from './ProjectThumbnailCard';
export function EditCard(_ref) {
  var ThumbLinkProps = _ref.ThumbLinkProps,
    props = _objectWithoutProperties(_ref, _excluded);
  var projectId = props.projectId,
    fallbackData = props.fallbackData;
  var _useProject = useProject({
      id: projectId,
      fallbackData: fallbackData
    }),
    projectInfo = _useProject.projectInfo;
  if (!projectInfo) return null;
  return __jsx("div", null, __jsx(ProjectThumbnailCard, _extends({
    ThumbLinkProps: _objectSpread({
      href: buildProjectEditRoute(projectInfo)
    }, ThumbLinkProps),
    "data-cy-primary-action": "edit-project",
    showPublished: false
  }, props)), __jsx(ProjectThumbnailLabel, null, projectInfo.title));
}