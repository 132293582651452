import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _extends from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["isBulkModeEnabled", "isBulkSelected", "onBulkSelection", "ThumbLinkProps"],
  _excluded2 = ["isBulkModeEnabled", "onSelect", "isSelected"],
  _excluded3 = ["isSelected", "children"];
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useBreakpointValue, forwardRef, useTheme } from '@chakra-ui/react';
import { Box, IconButton, LockIcon, MenuButton, MoreVerticalIcon, CheckmarkIcon, Text } from '@playful/design_system';
import React, { Fragment } from 'react';
import { TinyColor } from '@ctrl/tinycolor';
import { useProject } from '../../hooks/useProject';
import { buildProjectEditRoute } from '../../paths';
import { ProjectMenu } from '../../projectMenu/ProjectMenu';
import { useUserContext } from '../../user/UserContext';
import useDisclosure from '../../utils/useDisclosure';
import { ProjectThumbnailCard, ProjectThumbnailLabel } from './ProjectThumbnailCard';
// User Thumbnail card for use in Recent Projects, My Projects, etc. which has the project options menu
export function UserCard(_ref) {
  var isBulkModeEnabled = _ref.isBulkModeEnabled,
    isBulkSelected = _ref.isBulkSelected,
    onBulkSelection = _ref.onBulkSelection,
    ThumbLinkProps = _ref.ThumbLinkProps,
    props = _objectWithoutProperties(_ref, _excluded);
  var projectId = props.projectId;
  var _useUserContext = useUserContext(),
    user = _useUserContext.user;
  var _useProject = useProject({
      id: projectId
    }),
    projectInfo = _useProject.projectInfo;
  var _useDisclosure = useDisclosure(),
    onOpen = _useDisclosure.onOpen,
    isOpen = _useDisclosure.isOpen,
    onClose = _useDisclosure.onClose;
  var buttonSize = useBreakpointValue({
    base: 'xs',
    md: 'sm'
  });
  if (!projectInfo) return null;
  var modified = timeAgo(projectInfo.modified);
  var isValidBulkEnabled = isBulkModeEnabled && !projectInfo.permissions.locked;
  return __jsx("div", null, __jsx(BulkActionWrapper, {
    isBulkModeEnabled: !!isValidBulkEnabled,
    isSelected: !!isBulkSelected,
    onSelect: onBulkSelection
  }, __jsx(ProjectThumbnailCard, _extends({
    "data-cy-primary-action": "edit-project",
    hoverLabel: modified,
    showPublished: false,
    isMenuOpen: isOpen,
    ThumbLinkProps: _objectSpread({
      href: buildProjectEditRoute(projectInfo)
    }, ThumbLinkProps),
    version: projectInfo.version,
    isBulkModeEnabled: isValidBulkEnabled,
    Menu: !isValidBulkEnabled && __jsx(ProjectMenu, {
      projectInfo: projectInfo,
      onOpen: onOpen,
      onClose: onClose,
      isOpen: isOpen
    }, __jsx(MenuButton, {
      as: IconButton,
      position: 'absolute',
      display: {
        base: 'flex',
        md: 'none'
      },
      right: {
        base: 1.5,
        md: 2
      },
      top: {
        base: 1.5,
        md: 2
      },
      size: buttonSize,
      variant: 'solid',
      icon: __jsx(MoreVerticalIcon, null),
      backgroundColor: 'gray.100',
      _hover: {
        backgroundColor: 'white'
      },
      _groupHover: {
        display: 'flex'
      },
      _active: {
        display: 'flex'
      },
      opacity: 0.7,
      sx: {
        svg: {
          w: {
            base: '4',
            md: '5'
          },
          h: {
            base: '4',
            md: '5'
          },
          color: 'gray.700'
        }
      },
      "data-cy": "project-menu"
    }))
  }, props))), __jsx(MyProjectLabel, {
    projectInfo: projectInfo,
    user: user
  }));
}
function BulkActionWrapper(_ref2) {
  var isBulkModeEnabled = _ref2.isBulkModeEnabled,
    onSelect = _ref2.onSelect,
    isSelected = _ref2.isSelected,
    props = _objectWithoutProperties(_ref2, _excluded2);
  return isBulkModeEnabled ? __jsx(MediaOverlay, _extends({
    cursor: "pointer",
    isSelected: isSelected,
    onClick: onSelect
  }, props)) : __jsx(Fragment, props);
}
var MAX_TITLE_LENGTH = 67; // Roughly two lines of lower case text.

function MyProjectLabel(_ref3) {
  var projectInfo = _ref3.projectInfo,
    user = _ref3.user;
  var title = projectInfo.title;
  if (title.length > MAX_TITLE_LENGTH) {
    title = title.slice(0, MAX_TITLE_LENGTH) + '...';
  }
  if (projectInfo.permissions.locked && projectInfo.owner === user.id) {
    return __jsx(ProjectThumbnailLabel, null, __jsx(Box, {
      as: 'span',
      display: 'flex',
      gap: 1
    }, __jsx(Text, {
      fontSize: 'sm'
    }, title), ' ', __jsx(LockIcon, {
      flexShrink: 0,
      boxSize: 4,
      color: "secondary"
    })));
  }
  return __jsx(ProjectThumbnailLabel, null, title);
}

// Thanks https://stackoverflow.com/a/72817357/707320!
function timeAgo(input) {
  var date = input instanceof Date ? input : new Date(input);
  var secondsElapsed = (date.getTime() - Date.now()) / 1000;

  // Only "ago" format if within a year.
  if (Math.abs(secondsElapsed) < 3600 * 24 * 365) {
    var ranges = [['years', 3600 * 24 * 365], ['months', 3600 * 24 * 30], ['weeks', 3600 * 24 * 7], ['days', 3600 * 24], ['hours', 3600], ['minutes', 60], ['seconds', 1]];
    var formatter = new Intl.RelativeTimeFormat('en', {
      style: 'narrow'
    });
    for (var _i = 0, _ranges = ranges; _i < _ranges.length; _i++) {
      var _ranges$_i = _slicedToArray(_ranges[_i], 2),
        rangeType = _ranges$_i[0],
        rangeVal = _ranges$_i[1];
      if (rangeVal < Math.abs(secondsElapsed)) {
        var delta = secondsElapsed / rangeVal;
        return formatter.format(Math.round(delta), rangeType);
      }
    }
  }
  return Intl.DateTimeFormat('en').format(date);
}
var MediaOverlay = forwardRef(function (_ref4, ref) {
  var isSelected = _ref4.isSelected,
    children = _ref4.children,
    props = _objectWithoutProperties(_ref4, _excluded3);
  var theme = useTheme();
  var hoverBg = new TinyColor(theme.colors.blue[500]).setAlpha(0.25);
  return __jsx(Box, _extends({
    ref: ref,
    pos: "relative",
    role: "group",
    borderRadius: "4px",
    overflow: "hidden",
    _after: {
      content: '""',
      borderColor: 'blue.500',
      borderWidth: '3px',
      borderRadius: '4px',
      borderStyle: isSelected ? 'solid' : 'none',
      pos: 'absolute',
      w: '100%',
      h: '100%',
      pointerEvents: 'none',
      transition: 'all 0.1s',
      top: 0,
      left: 0,
      boxSizing: 'border-box'
    },
    _hover: isSelected ? {
      _after: {
        bg: hoverBg.toRgbString()
      }
    } : undefined
  }, props), children, __jsx(Box, {
    as: "span",
    color: isSelected ? 'white' : 'gray.300',
    bg: isSelected ? 'blue.500' : 'gray.100',
    pos: "absolute",
    top: "8px",
    left: "8px",
    borderRadius: "2px",
    display: "flex",
    opacity: isSelected ? 1 : 0,
    alignItems: "center",
    justifyContent: "center",
    transition: "color 0.2s, background-color 0.2s",
    width: "24px",
    height: "24px",
    pointerEvents: "none",
    _groupHover: isSelected ? undefined : {
      opacity: 1
    }
  }, __jsx(CheckmarkIcon, {
    boxSize: "16px"
  })));
});